import './Productsimilar.css'
import React, { useEffect, useState, useContext } from 'react'
import { useParams, Link } from 'react-router-dom'
import {
    Box,
    Button,
    Container,
    Tooltip,
    Typography,
    Dialog,
    DialogActions, Tab, Tabs as Tabss,
    DialogContent,
    DialogContentText,
    Chip,
    Rating,
    ButtonGroup, CircularProgress, Backdrop,
    Skeleton,
    IconButton, OutlinedInput, InputLabel, InputAdornment, FormControl, Select, MenuItem, Alert, Snackbar, ListItem,
    Table, TableBody, TableRow
} from '@mui/material';
import { AiFillCloseCircle, AiOutlineLogin } from 'react-icons/ai'
import { TbDiscount2 } from 'react-icons/tb'
import axios from 'axios';
import ProductCard from '../../Components/Card/Product Card/ProductCard';
import { Transition, getSingleProduct, getStoreData } from '../../Constants/Constant';
import CopyRight from '../../Components/Footer/Footer';
import { FcPhone } from "react-icons/fc";
import { CgProfile } from 'react-icons/cg';
import { LiaShippingFastSolid } from "react-icons/lia";
import { RxDimensions } from "react-icons/rx";
import { FiInfo } from 'react-icons/fi';
import { FiPhoneOutgoing } from "react-icons/fi";

import { useTheme } from '@mui/material/styles';
import wilaya from "../../Assets/wilaya.json";
import communes from "../../Assets/communes.json";
import BuyButton from '../../Components/BuyButton/BuyButton';
import Tabs from '../../Components/Tabs/Tabs';
import 'react-alice-carousel/lib/alice-carousel.css';
import ProductCarousel from '../../Components/ProductCarousl/ProductCarousel';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { toast } from 'react-toastify';
import ContactUs from '../../Components/ContactUs/ContactUs';
import ReactPixel from 'react-facebook-pixel';
import RichTextEditor from '../../Components/RichTextEditor/RichTextEditor';

const handleDragStart = (e) => e.preventDefault();

const Items = (images) => (images?.map((item) => <img src={item} onDragStart={handleDragStart} role="presentation" />))

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 18
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

var phoneregex = /^(00213|\+213|0)(5|6|7)[0-9]{8}$/
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name, theme) {
    return {
        fontWeight:
            theme.typography.fontWeightMedium
    };
}

const ProductDetail = () => {
    const theme = useTheme();
    const [personName, setPersonName] = React.useState([]);
    const [wilay, setWilay] = useState('1');
    const [inputs, setInputs] = useState({ phone1: null });
    const [wilayas, setWilayas] = useState('');
    const [commune, setCommune] = useState('');
    const [state, setState] = useState({
        name: '',
        phone: '', variants: '', qte: 0, open: false
    });
    const [variant, setVariant] = useState('');
    // const [open, setOpen] = React.useState(false);


    // const { cart, setCart, wishlistData, setWishlistData } = useContext(ContextFunction)
    const [openAlert, setOpenAlert] = useState(false);
    const { id, cat } = useParams()
    const [product, setProduct] = useState([])
    const [similarProduct, setSimilarProduct] = useState([])
    const [productQuantity, setProductQuantity] = useState(1)
    const [loading, setLoading] = useState(true);
    const [valuetab, setValuetab] = React.useState(0);

    const handleChangeTab = (event, newValue) => {
        setValuetab(newValue);
    };

    let authToken = localStorage.getItem('Authorization')
    let setProceed = authToken ? true : false
    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        const {
            target: { name },
        } = event;
        setPersonName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );

        if (name === 'wilaya') {
            setWilayas([value])
            if (value) {
                let wil = wilaya.filter((val) => val.ar_name === value);
                if (wil.length) {
                    setWilay(wil[0].id)
                }
            }
        }
        else if (name == 'commune')
            setCommune([value])
    };

    useEffect(() => {
        getSingleProduct(setProduct, id, setLoading);
        getSimilarProducts();
        getStoreData(setInputs)
        window.scroll(0, 0)
    }, [id]);
    /*const shareProduct = (product) => {

        const data = {
            text: product.name,
            title: "e-shopit",
            url: `https://e-shopit.vercel.app/Detail/type/${cat}/${id}`
        }
        if (navigator.canShare && navigator.canShare(data)) {
            navigator.share(data);
        }
        else {
            toast.error("browser not support", { autoClose: 500, theme: 'colored' })
        }

    }*/
    const getSimilarProducts = async () => {
        const { data } = await axios.post(`${process.env.REACT_APP_PRODUCT_TYPE}`, { userType: cat })
        setSimilarProduct(data)
    }

    const Buy = async () => {
        // setOpen(true);
        if (!wilayas || wilayas.length < 1 || !commune || commune.length < 1 || !phoneregex.test(state.phone)
            || !state.name) {
            toast.warning('يرجى مراجعة المعلومات', { autoClose: 500, theme: 'colored', position: "bottom-center" });
        } else {
            try {
                const { data } = await axios.post(`${process.env.REACT_APP_BUY_PRODUCT}`, {
                    phoneNumber: state.phone, quantity: productQuantity, name: state.name, product: { id: product?._id, variant: variant ? product?.name + ', ' + variant?.name + ' : ' + variant?.val : '' }
                    , address: wilayas[0] + ' , ' + commune[0], commune: commune[0], wilaya: wilayas[0]
                });
                if (data.success) {
                    // Track the purchase event
                    if (!data?.fail) {
                        ReactPixel.track('Purchase', {
                            value: productQuantity * 10,
                            currency: 'DZD',
                        });
                    }
                    toast.success('تم تسجيل طلبيتكم سوف يتم الإتصال بكم قريبا', { autoClose: 2000, theme: 'colored', position: "bottom-center" });
                    toast.warning(`إذا لم يتم الإتصال بكم خلال 48 ساعة يمكنم الإتصال بنا على : 
                        - ${inputs.phone1}
                        ${inputs?.phone2 ? `- ${inputs.phone2}` : ''}
                        `, { autoClose: 10000, theme: 'colored', position: "bottom-center" })
                } else {
                    toast.error('يوجد خلل', { autoClose: 500, theme: 'colored', position: "bottom-center" });
                }

            } catch (err) {
                toast.error('يوجد خلل', { autoClose: 500, theme: 'colored', position: "bottom-center" });
                // setOpen(false);
                console.log(err);

            }
        }
    }
    /*let data = [];
    if (cat === 'shoe') {
        data.push(product?.brand, product?.gender, product?.category)
    }
    else if (cat === 'book') {
        data.push(product.author, product.category)
    }
    else if (cat === 'cloths') {
        data.push(product.category, cat)
    }
    else if (cat === 'electronics') {
        data.push(product.category, cat)
    }
    else if (cat === 'jewelry') {
        data.push(cat)
    }*/
    const increaseQuantity = () => {
        setProductQuantity((prev) => prev + 1)
        if (productQuantity >= 20) {
            setProductQuantity(20)
        }
    }
    const decreaseQuantity = () => {
        setProductQuantity((prev) => prev - 1)
        if (productQuantity <= 1) {
            setProductQuantity(1)
        }
    }
    const handleChangle = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setState({
            ...state,
            [name]: value
        });

    }
    const setVariants = (checked) => {
        /*let varient = variant;
        const n = varient?.leng;
        let found = false;
        for (let i = 0; i < n; i++) {
            if (varient[i] === checked) {
                varient[i] = checked;
                found = true;
            }
        }
        if (!found) {
            varient.push(checked);
        }*/
        setVariant(checked);
    }
    return (
        <div dir='rtl'>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 5 }}
                open={state.open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <Container maxWidth='xl'  >
                <Dialog
                    open={openAlert}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => setOpenAlert(false)}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogContent sx={{ width: { xs: 280, md: 350, xl: 400 } }}>
                        <DialogContentText style={{ textAlign: 'center' }} id="alert-dialog-slide-description">
                            Please Login To Proceed
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
                        <Link to="/login"> <Button variant='contained' endIcon={<AiOutlineLogin />} color='primary'>Login</Button></Link>
                        <Button variant='contained' color='error'
                            onClick={() => setOpenAlert(false)} endIcon={<AiFillCloseCircle />}>Close</Button>
                    </DialogActions>
                </Dialog>

                <main className='main-content'>
                    <Typography align='center' variant='h4' >{product.name}</Typography>
                    {loading ? (
                        <Skeleton variant='rectangular' height={400} />
                    ) : (
                        <div className="product-image" >
                            <div className='detail-img-box'  >
                                <ProductCarousel images={product.image} />

                                <br />
                            </div>
                        </div>
                    )}
                    {loading ? (
                        <section style={{ display: 'flex', flexWrap: "wrap", width: "100%", justifyContent: "space-around", alignItems: 'center' }}>
                            <Skeleton variant='rectangular' height={200} width="200px" />
                            <Skeleton variant='text' height={400} width={700} />

                        </section>

                    ) : (
                        <section className='product-details'>
                            <div style={{ display: 'flex', gap: 20 }}>
                                {product?.new_price && product?.new_price > 0 ? <Typography style={{ fontWeight: 'bolder', fontSize: '22px' }} variant="h6" color="red"><s> {product.price + ' د.ج'} </s> </Typography> : null}
                                <Typography style={{ fontWeight: 'bolder', fontSize: '22px' }} variant="h6" color="primary">
                                    {product?.new_price && product?.new_price > 0 ? product.new_price + ' د.ج' : product.price + ' د.ج'}
                                </Typography>
                            </div>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    // background: 'red',
                                    '& > *': {
                                        m: 2,
                                    },
                                }}
                            >
                                <div dir='ltr'  >
                                    <ButtonGroup size='large' variant="outlined" aria-label="outlined button group" style={{ display: 'flex', justifyContent: 'flex-end', fontWeight: 'bolder' }}  >
                                        <Button style={{ fontWeight: 'bolder', fontSize: '22px' }} onClick={increaseQuantity}>+</Button>
                                        <Button style={{ fontWeight: 'bolder', fontSize: '16px' }} >{productQuantity}</Button>
                                        <Button style={{ fontWeight: 'bolder', fontSize: '22px' }} onClick={decreaseQuantity}>-</Button>
                                    </ButtonGroup>
                                </div>

                                {product?.new_price && product.price && product?.new_price > 0 && product?.price > 0 ? <Chip style={{ fontWeight: 'bolder', fontSize: '16px' }}
                                    label={"خصم يصل إلى %" + (100 - product?.new_price * 100 / product?.price).toFixed(0)}
                                    variant="outlined"
                                    sx={{ background: '#1976d2', color: 'white', width: '68%', fontWeight: 'bold' }}
                                    avatar={<TbDiscount2 color='white' />}
                                /> : null}
                            </Box>
                            <Rating size='large' name="read-only" value={Math.round(product.rating)} readOnly precision={0.5} />
                            <Tabss
                                value={valuetab}
                                onChange={handleChangeTab}
                                aria-label="icon position tabs example"
                                style={{ width: '100%', display: 'flex', justifyContent: 'start' }}
                            >
                                <Tab value={0} icon={<FiInfo size='2em' />} iconPosition="top" label="المنتج" />
                                <Tab value={1} icon={<RxDimensions size='2em' />} iconPosition="top" label="المقاسات" />
                                <Tab value={2} icon={<LiaShippingFastSolid size='2em' />} iconPosition="top" label="التوصيل" />
                                <Tab value={3} icon={<FiPhoneOutgoing size='2em' />} iconPosition="top" label="إتصلوا بنا" />

                            </Tabss>
                            {valuetab === 0 ?
                                <RichTextEditor readOnly={true} description={product.description} />
                                : valuetab === 1 ?
                                    <Table sx={{ minWidth: '50%' }} aria-label="customized table">
                                        <TableBody>
                                            {product.height &&
                                                <StyledTableRow key={0}>
                                                    <StyledTableCell align="right" component="th" scope="row">
                                                        الطول&nbsp;(سم)
                                                    </StyledTableCell>
                                                    <StyledTableCell style={{ fontWeight: 'bold' }} align="right">{product.height}</StyledTableCell>
                                                </StyledTableRow>
                                            }
                                            {product.width &&
                                                <StyledTableRow key={1}>
                                                    <StyledTableCell align="right" component="th" scope="row">
                                                        الطول&nbsp;(سم)
                                                    </StyledTableCell>
                                                    <StyledTableCell style={{ fontWeight: 'bold' }} align="right">{product.width}</StyledTableCell>
                                                </StyledTableRow>
                                            }
                                            {product.lngth &&
                                                <StyledTableRow key={2}>
                                                    <StyledTableCell align="right" component="th" scope="row">
                                                        العرض&nbsp;(سم)
                                                    </StyledTableCell>
                                                    <StyledTableCell style={{ fontWeight: 'bold' }} align="right">{product.lngth}</StyledTableCell>
                                                </StyledTableRow>
                                            }
                                            {product.weight &&
                                                <StyledTableRow key={3}>
                                                    <StyledTableCell align="right" component="th" scope="row">
                                                        الوزن&nbsp;(غ)
                                                    </StyledTableCell>
                                                    <StyledTableCell style={{ fontWeight: 'bold' }} align="right">{product.weight}</StyledTableCell>
                                                </StyledTableRow>
                                            }

                                        </TableBody>
                                    </Table>
                                    :
                                    valuetab === 2 ?
                                        <Typography fontFamily={'Cairo'} align='justify' variant="body1" gutterBottom> نحن فخورون بتقديم خدمة شحن مميزة لعملائنا، حيث نضمن لك تجربة شحن سلسة وسريعة. نحن ندرك أهمية وصول طلباتك في الوقت المناسب وبحالة ممتازة، لذا نحرص على التعامل مع شركات شحن موثوقة وفعّالة. بالإضافة إلى ذلك، نقدم خيارات شحن متعددة تناسب احتياجاتك، سواء كنت تحتاج إلى شحن سريع أو شحن اقتصادي. تتيح لك خدمتنا تتبع الشحنة في كل خطوة حتى تصل إلى بابك، مما يمنحك راحة البال والثقة بأن مشترياتك في أيدٍ أمينة. اخترنا لأننا نؤمن بأن خدمة الشحن الممتازة هي جزء لا يتجزأ من تجربة تسوق رائعة.
                                        </Typography>
                                        :
                                        valuetab === 3 ?
                                            <ul>
                                                {inputs.phone1 ? <li> <a href={`tel:${inputs.phone1}`}> {`الهاتف: ${inputs.phone1}`} </a></li> : null}
                                                {inputs.email ? <li> <a href={`email:${inputs.email}`}>{`البريد الإلكتروني: ${inputs.email}`} </a></li> : null}
                                                {inputs.address ? <li>{`العنوان: ${inputs.address}`}</li> : null}
                                            </ul>
                                            :
                                            null
                            }


                            <Tabs variants={product.variants} setVariants={setVariants} />

                        </section>
                    )}
                    <FormControl fullWidth required style={{ marginTop: '14px' }} variant="outlined">
                        <OutlinedInput onChange={handleChangle} name="name" value={state.name}
                            id="outlined-adornment-password"
                            type='text'
                            startAdornment={
                                <InputAdornment position='end'>
                                    <IconButton style={{ marginRight: '3px' }}
                                        aria-label="toggle password visibility"
                                        onClick={() => console.log('mlazkelmakz')}
                                        edge="start"
                                    >
                                        <CgProfile />
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Password"
                        />
                        <InputLabel htmlFor="outlined-adornment-password">الإسم واللقب</InputLabel>
                    </FormControl>
                    <FormControl fullWidth required style={{ marginTop: '14px' }} variant="outlined" error={state.phone && !phoneregex.test(state.phone)} >
                        <OutlinedInput onChange={handleChangle} name="phone" value={state.phone} onBlur={() => {
                            if (!phoneregex.test(state.phone)) {
                                toast.error('يرجى إستعمال رقم هاتف صحيح', { autoClose: 500, theme: 'colored', position: "bottom-center" });
                            }
                        }}
                            id="outlined-adornment-password"
                            type='number'
                            startAdornment={
                                <InputAdornment position="start">
                                    <IconButton style={{ marginRight: '3px' }}
                                        aria-label="toggle password visibility"
                                        onClick={() => console.log('mlazkelmakz')}
                                        edge="end"
                                    >
                                        <FcPhone />
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Password"
                        />
                        <InputLabel htmlFor="outlined-adornment-password">رقم الهاتف</InputLabel>
                    </FormControl>

                    <FormControl fullWidth required style={{ marginTop: '14px' }}  >
                        <InputLabel id="chip01">الولاية</InputLabel>
                        <Select
                            labelId="chip1-label"
                            id="chip1"
                            name='wilaya'
                            value={wilayas}
                            onChange={handleChange}
                            input={<OutlinedInput id="select-chip1" label="Chip" />}
                            renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    {selected?.map((value) => (
                                        <Chip key={value} label={value} />
                                    ))}
                                </Box>
                            )}
                            MenuProps={MenuProps}
                        >
                            {wilaya.map((val) => (
                                <MenuItem
                                    key={val.id}
                                    value={val.ar_name}
                                    style={getStyles(val.ar_name, theme)}
                                >
                                    {val.code + ' - ' + val.name + ' - ' + val.ar_name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth required style={{ marginTop: '14px' }}  >
                        <InputLabel id="chip02">البلدية</InputLabel>
                        <Select readOnly={!wilayas}
                            labelId="chip2-label"
                            id="chip2"
                            name='commune'
                            value={commune}
                            onChange={handleChange}
                            input={<OutlinedInput id="select-chip2" label="Chip" />}
                            renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    {selected?.map((value) => (
                                        <Chip key={value} label={value} />
                                    ))}
                                </Box>
                            )}
                            MenuProps={MenuProps}
                        >
                            {communes.filter((val) => val.wilaya_id == wilay).map(({ id, ar_name, name, post_code }, index) => (

                                <MenuItem
                                    key={index}
                                    value={ar_name}
                                    style={getStyles(ar_name, theme)}
                                >
                                    {name + ' - ' + ar_name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <BuyButton product={variant} title={wilayas && commune && phoneregex.test(state.phone)
                        && state.name ? 'تقديم الطلبية' : 'يرجى إكمال المعلومات'

                    } enable={!state.open && wilayas && commune && phoneregex.test(state.phone)
                        && state.name} onClicked={() => Buy()} />
                </main>


                <Typography sx={{ marginTop: 10, marginBottom: 5, fontWeight: 'bold', textAlign: 'center', fontSize: '30px' }}>منتجات مماثلة</Typography>
                <Box>
                    <Box className='similarProduct' sx={{ display: 'flex', overflowX: 'auto', marginBottom: 10 }}>
                        {
                            similarProduct.filter(prod => prod._id !== id)?.map(prod => (
                                <Link to={`/Detail/type/${prod.category}/${prod._id}`} key={prod._id}>
                                    <ProductCard prod={prod} />
                                </Link>
                            ))
                        }
                    </Box>
                </Box>

            </Container >
            <CopyRight sx={{ mt: 8, mb: 10 }} />

        </div>
    )
}

export default ProductDetail